import React from 'react';
import css from './BannerCliqueFarma.module.scss';

const BannerCliqueFarma = () => (
  <a href="https://www.cliquefarma.com.br/">
    <div className={css.banner} />
  </a>
);

export default BannerCliqueFarma;
